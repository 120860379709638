<template>
  <ToggleHeader
    class="field"
    title="Companion insight"
    :visible="visible"
    @toggle="toggle"
  >
    <transition
      enter-active-class="animated faster fadeIn"
      leave-active-class="animated faster fadeOut"
      mode="out-in"
    >
      <div v-if="visible">
        <hr class="mt-0 has-background-dark" />
        <div class="columns is-multiline is-mobile">
          <div class="column is-6 has-text-info">
            <p>Retail</p>
          </div>
          <div
            class="column is-6 has-text-right has-text-info has-text-weight-semibold"
          >
            <p>{{ data.retail ? formatMoney(data.retail.pooled) : 'N/A' }}</p>
          </div>
          <div class="column is-6 has-text-primary">
            <p>Trade</p>
          </div>
          <div
            class="column is-6 has-text-right has-text-primary has-text-weight-semibold"
          >
            {{ data.trade ? formatMoney(data.trade) : 'N/A' }}
          </div>
          <div class="column is-12">
            <section class="box has-background-white-ter is-rounded">
              <div class="content">
                <p class="title is-5 has-text-dark">Get even greater insight</p>
                <p class="has-text-grey-dark" v-if="!isCompanionUser">
                  Subscribe today and use Percayso Vehicle Intelligence
                  Companion to help maximise your profit – buy it for the
                  correct value, price it right and easily compare it to
                  matching vehicles on the market
                </p>
                <p class="has-text-grey-dark" v-else>
                  View this vehicle in Percayso Vehicle Intelligence Companion
                  to help maximise your profit – buy it for the correct value,
                  price it right and easily compare it to matching vehicles on
                  the market
                </p>
                <div class="buttons is-right">
                  <button
                    class="button is-info is-rounded"
                    @click="goToCompanion"
                    v-if="!isCompanionUser"
                  >
                    Get started
                  </button>
                  <button
                    class="button is-info is-rounded"
                    :class="{ 'is-loading': $wait.is('loading') }"
                    @click="goToCompanion"
                    v-else
                  >
                    Go to Companion
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </transition>
  </ToggleHeader>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatMoney } from 'accounting'
import * as companion from 'modules/companion/services'
export default {
  name: 'CazanaInsight',
  data: () => ({ visible: true }),
  components: {
    ToggleHeader: () => import('core/components/ToggleHeader')
  },
  props: {
    data: { type: Object, required: true }
  },
  computed: {
    ...mapGetters({
      vrm: 'autocheck/vrm',
      mileage: 'autocheck/mileage',
      date: 'autocheck/date',
      isCompanionUser: 'auth/isCompanionUser'
    })
  },
  methods: {
    formatMoney(value) {
      return formatMoney(value, '£', 0)
    },
    toggle() {
      const action = this.visible ? 'close' : 'open'
      this.$mxp.track(`autocheck_${action}_cazana_insight_collapsible`)
      this.visible = !this.visible
    },
    async goToCompanion() {
      const { isCompanionUser, vrm, mileage, date } = this
      if (!isCompanionUser) return this.$modal.open('companion/UpsellCompanion')
      else if (!this.$wait.is('loading')) {
        this.$mxp.track('autocheck_companion_request')

        try {
          this.$wait.start('loading')
          await companion.value({ vrm, mileage, date })
        } finally {
          this.$wait.end('loading')
        }
      }
    }
  }
}
</script>
